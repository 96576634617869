<template>
    <AdminToast />
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-1 pb-1'>
                    <div class='header-left-section flex align-items-center justify-content-start mb-3 md:mb-0'>
                        <div class='profile relative'>
                            <img src='http://image.a1catering.com.tr/YoneticiProfil/ProfilDefault.png' width='100'
                                 class='border-circle border-3 border-solid' />
                        </div>
                    </div>
                </div>

                <span>
					<hr>
				</span>


            </div>


        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <div class='grid'>

                    <div class='col-12'>

                        <div class='field grid mb-5'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'>Projeler<span
                                class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                            <div class='col-12 md:col-10'>
                                <Dropdown id='department' v-model='selectedProject' :options='projectList'
                                          optionLabel='name' option-value='id' placeholder='Seçim Yapın' @change='resetSelectedEmployee'></Dropdown>
                            </div>
                        </div>
                        <div class='field grid mb-5'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'>Departman<span
                                class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                            <div class='col-12 md:col-10'>
                                <Dropdown id='department' v-model='selectedDepartment' :options='departmentList'
                                          optionLabel='name' option-value='name' placeholder='Seçim Yapın' @change='resetSelectedEmployee'></Dropdown>
                            </div>
                        </div>

                        <div class='field grid mb-5' v-if='showEmployeeList'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'>Çalışan<span
                                class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                            <div class='col-12 md:col-10'>
                                <Dropdown id='department' v-model='selectedEmployee' :options='employeeList'
                                          optionLabel='employee.fullName' option-value='employeeId'
                                          placeholder='Seçim Yapın'></Dropdown>
                            </div>
                        </div>

                        <div class='field grid mb-5'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'>Mesaj İçeriği<span
                                class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                            <div class='col-12 md:col-10'>
                                <Textarea rows='5' cols='30' id='name' type='text' autocomplete='off'
                                          v-model='dataItem.text' />
                            </div>
                        </div>


                        <div class='field grid'>
                            <label class='col-12 mb-2 md:col-2 md:mb-4'></label>
                            <div class='col-12 md:col-10'>
                                <Button id='save' label='Gönder' icon='pi pi-send' @click='save'
                                        v-bind:disabled='isProcessing'></Button>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>


    </div>
</template>

<script>

import { showErrorMessage, showSuccessMessage, showValidationMessage } from '@/components/shared/toast/toastFunction';
import AdminToast from '@/components/shared/toast/adminToast';
import SmsService from '@/services/smsService';
import { getDepartmentList } from '@/pages/common/commonFunctions';
import ProjectEmployeeService from '@/services/projectEmployeeService';
import { getActiveProjectId } from '@/pages/common/commonConstantFunctions';
import ProjectService from '@/services/projectService';

export default {
    _smsService: null,
    _projectEmployeeService: null,
    _projectService: null,
    components: { AdminToast },

    computed: {
        showEmployeeList() {
            return !(this.selectedDepartment != 'Tümü' || this.selectedProject != -1);
        },
    },
    created() {
        this._projectService = new ProjectService();
        this._smsService = new SmsService();
        this._projectEmployeeService = new ProjectEmployeeService();
        this.projectId = getActiveProjectId();
    },
    async mounted() {
        this.loadingIndicator = true;
        this.departmentList = await getDepartmentList();
        this.departmentList.push(
            {
                name: 'Tümü',
            });

        await this.refreshProjectEmployeeList();
        await this.fillProject();
        this.selectedProject = -1;
    },


    data() {
        return {
            isProcessing: false,
            display: false,
            visibleLeft: false,
            projectId: 0,
            dataItem: {
                phoneNumber: 0,
                text: '',
            },
            departmentList: [],
            selectedDepartment: 'Tümü',
            selectedEmployee: 0,
            employeeList: [],
            projectList: [],
            selectedProject: -1,
        };
    },

    methods: {
        resetSelectedEmployee() {
          this.selectedEmployee = 0
        },
        open() {
            this.display = true;
        },
        close() {
            this.display = false;
        },
        async save() {
            this.isProcessing = true;
            if (await this.validateForm()) {

                if (this.selectedDepartment == 'Tümü' && this.selectedEmployee > 0) {
                    let insertResponse = await this._smsService.sendEmployeeSms(this.selectedEmployee, this.dataItem.text, this.projectId);
                    if (insertResponse.isSuccess) {
                        showSuccessMessage(this, 'Sms Gönderilmek üzere sıraya alındı');
                    } else {
                        showErrorMessage(this, 'SMS Göndermede Sorun İle Karşılaşıldı');
                    }
                } else if (this.selectedDepartment != 'Tümü' && this.selectedEmployee == 0) {
                    let insertResponse = await this._smsService.sendEmployeeDepartmentSms(this.selectedDepartment, this.dataItem.text, this.selectedProject);
                    if (insertResponse.isSuccess) {
                        showSuccessMessage(this, 'Sms Gönderilmek üzere sıraya alındı');
                    } else {
                        showErrorMessage(this, 'SMS Göndermede Sorun İle Karşılaşıldı');
                    }
                } else if (this.selectedDepartment == 'Tümü' && this.selectedEmployee == 0 && this.selectedProject == -1) {
                    let insertResponse = await this._smsService.sendAllEmployeeSms(this.dataItem.text);
                    if (insertResponse.isSuccess) {
                        showSuccessMessage(this, 'Sms Gönderilmek üzere sıraya alındı');
                    } else {
                        showErrorMessage(this, 'SMS Göndermede Sorun İle Karşılaşıldı');
                    }
                } else {
                    let insertResponse = await this._smsService.sendEmployeeProjectSms(this.dataItem.text, this.selectedProject);
                    if (insertResponse.isSuccess) {
                        showSuccessMessage(this, 'Sms Gönderilmek üzere sıraya alındı');
                    } else {
                        showErrorMessage(this, 'SMS Göndermede Sorun İle Karşılaşıldı');
                    }
                }

            }
            this.isProcessing = false;
        },
        async validateForm() {

            if (this.dataItem.text == '') {
                showValidationMessage(this, 'Mesaj Alanı Boş Bırakılamaz');
                return false;
            }

            return true;
        },

        async refreshProjectEmployeeList() {
            const response = await this._projectEmployeeService.getAllActiveProjectEmployees();
            if (response.isSuccess) {
                this.employeeList = response.data;
                this.employeeList.push({
                    employeeId: 0,
                    employee: {
                        fullName: 'Tümü',
                    },
                });
            }

            this.loadingIndicator = false;
        },
        async fillProject() {
            let projectResponse = await this._projectService.getAllProjects();
            if (projectResponse.isSuccess) {
                this.projectList = projectResponse.data;
                this.projectList.push({
                    id: -1,
                    name: 'Tümü'
                });
            }
        },
    },

};

</script>


<style scoped>

</style>
